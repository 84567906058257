@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bebas+Neue&family=Ceviche+One&family=Noto+Serif+Display:wght@300;400;600&family=Zen+Kurenaido&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
font-family: 'Archivo Black', sans-serif;
font-family: 'Bebas Neue', cursive;
font-family: 'Ceviche One', cursive;
font-family: 'Noto Serif Display', serif;
font-family: 'Zen Kurenaido', sans-serif; 
*/

h1,
h2,
h3,
h4 {
  font-family: "Noto Serif Display";
  /* color: red; */
}
h1 {
  font-size: 45px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
p {
  font-size: 14px;
}

.styles_header__1zqbi {
  color: red;
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: red 2px dotted;
}

.styles_iconWrapper__2Q6mV {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.styles_icon__1z_Sz {
  padding: 5px;
  margin: 5px;
  color: red;
}

.styles_projectWrapper__3Voy_ {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.styles_projectItem__22vNi {
  padding: 5px;
  margin: 5px;
  width: 350px;
  border-radius: 5px;
}
.styles_focused__Hh8lq {
  border: red 2px solid;
}
.styles_unfocused__36orI {
  border: #ffc2c2 1px solid;
  color: #ffc2c2;
}
.styles_unfocused__36orI .styles_header__1zqbi {
  color: #ffc2c2;
  border-bottom: 1px dotted;
}

.styles_tagWrapper__1oerX {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Archivo Black";
}
.styles_tag__OdgPh {
  padding: 0px 5px 0px 5px;
  margin: 3px;
  line-height: 0%;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: red;
  border: 1px dotted;
}
.styles_tagHeader__2UZWK {
  line-height: 0%;
  font-weight: bold;
  margin-right: 10px;
}

.styles_colorRed__2kwR8 {
  border-color: red;
  color: red;
}
.styles_colorBlue__16b41 {
  border-color: blue;
  color: blue;
}
.styles_colorWhite__16sH0 {
  background-color: white;
}
.styles_colorGreen__1Uph8 {
  background-color: lightseagreen;
}

.styles_colorActive__1_Iqk {
  /* background-color: white;
  border: red 1px solid;
  color: red; */
  opacity: 1;
}
.styles_colorInactive__3hGSR {
  opacity: 0.4;
  /* background-color: white;
  color: #ffc2c2;
  border: #ffc2c2 1px solid; */
}

.styles_selectorWrapper__rl0YG {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
}
.styles_selectorButton__3ZQJA {
  margin: 5px;
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  border: red 1px solid;
  color: red;
  transition: opacity 0.5s ease-out;
}
.styles_selectorButtonText__3chJa {
  padding: 0px;
}
.styles_selectorButton__3ZQJA:hover {
  opacity: 0.7;
}

.styles_linkPlacer__v3uhO {
  align-items: flex-end;
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}

.styles_clickable__2XpSa {
  cursor: pointer;
}

