@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bebas+Neue&family=Ceviche+One&family=Noto+Serif+Display:wght@300;400;600&family=Zen+Kurenaido&display=swap");

/* 
font-family: 'Archivo Black', sans-serif;
font-family: 'Bebas Neue', cursive;
font-family: 'Ceviche One', cursive;
font-family: 'Noto Serif Display', serif;
font-family: 'Zen Kurenaido', sans-serif; 
*/

h1,
h2,
h3,
h4 {
  font-family: "Noto Serif Display";
  /* color: red; */
}
h1 {
  font-size: 45px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
p {
  font-size: 14px;
}

.header {
  color: red;
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: red 2px dotted;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon {
  padding: 5px;
  margin: 5px;
  color: red;
}

.projectWrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  min-height: fit-content;
}
.projectItem {
  padding: 5px;
  margin: 5px;
  width: 350px;
  border-radius: 5px;
}
.focused {
  border: red 2px solid;
}
.unfocused {
  border: #ffc2c2 1px solid;
  color: #ffc2c2;
}
.unfocused .header {
  color: #ffc2c2;
  border-bottom: 1px dotted;
}

.tagWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Archivo Black";
}
.tag {
  padding: 0px 5px 0px 5px;
  margin: 3px;
  line-height: 0%;
  border-radius: 5px;
  width: fit-content;
  color: red;
  border: 1px dotted;
}
.tagHeader {
  line-height: 0%;
  font-weight: bold;
  margin-right: 10px;
}

.colorRed {
  border-color: red;
  color: red;
}
.colorBlue {
  border-color: blue;
  color: blue;
}
.colorWhite {
  background-color: white;
}
.colorGreen {
  background-color: lightseagreen;
}

.colorActive {
  /* background-color: white;
  border: red 1px solid;
  color: red; */
  opacity: 1;
}
.colorInactive {
  opacity: 0.4;
  /* background-color: white;
  color: #ffc2c2;
  border: #ffc2c2 1px solid; */
}

.selectorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
}
.selectorButton {
  margin: 5px;
  padding: 10px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  border: red 1px solid;
  color: red;
  transition: opacity 0.5s ease-out;
}
.selectorButtonText {
  padding: 0px;
}
.selectorButton:hover {
  opacity: 0.7;
}

.linkPlacer {
  align-items: flex-end;
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}

.clickable {
  cursor: pointer;
}
